<template>
  <div class="animated fadeIn">
    <loading v-if="vLoading" />
    <v-row class="justify-content-center">
      <v-col cols="9">
        <v-card-title
          v-if="choose"
          style="background-color: transparent !important"
        >
          <h3>افزودن سرویس</h3>
        </v-card-title>
        <v-card-title v-else style="background-color: transparent !important">
          <h3>افزودن سرویس {{ label }}</h3>
        </v-card-title>
        <v-card class="cards">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col>
                  <v-select
                    @change="emptyData()"
                    placeholder="لطفا سرویس مورد‌ نظر را انتخاب کنید"
                    v-if="choose"
                    outlined
                    dense
                    :items="categories"
                    v-model="type"
                    hide-details
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="type">
                <v-col cols="12" sm="12" md="6" lg="6" xl="6">
                  <v-text-field
                    label="عنوان"
                    v-model="service.name"
                    outlined
                    dense
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col
                  v-if="type == 'clinic'"
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                >
                  <v-select
                    label="شیوه ارائه خدمت"
                    v-model="service.type"
                    :items="serviceTypes"
                    item-text="text"
                    item-value="value"
                    menu-props="auto"
                    hide-details
                    outlined
                    dense
                  ></v-select>
                </v-col>
              </v-row>
              <v-row v-if="type == 'clinic'">
                <v-col>
                  <vuetify-money
                    v-model="service.expense"
                    label="هزینه(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>

              <v-row v-if="type">
                <v-col>
                  <vuetify-money
                    v-model="service.cost"
                    label="قیمت(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row v-if="type == 'clinic'">
                <v-col>
                  <vuetify-money
                    v-model="service.protectiveExpense"
                    label="هزینه اقلام حفاظتی(ریال)"
                    :valueWhenIsEmpty="whenIsEmpty"
                    :options="vMoneyOptions"
                    type="number"
                    outlined
                    dense
                    hide-details
                  />
                </v-col>
              </v-row>
              <v-row v-if="type == 'clinic'">
                <v-col class="pb-0">
                  <v-text-field
                    label="سهم پزشک(درصد)"
                    v-model="service.docPercent"
                    type="number"
                    outlined
                    dense
                    hide-details
                    :rules="[percent]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row v-if="type == 'clinic' || type == 'dental'">
                <v-col class="pt-0">
                  <v-checkbox
                    v-model="service.isSelectableByPatient"
                    color="primary"
                  >
                    <template v-slot:label>
                      <span class="mt-2"
                        >امکان انتخاب این سرویس توسط بیمار وجود دارد.</span
                      >
                    </template>
                  </v-checkbox>

                  <div
                    v-if="service.isSelectableByPatient"
                    class="d-flex align-end"
                  >
                    <div
                      class="img-box pointer"
                      @click="$refs.profileImage.$refs.input.click()"
                    >
                      <img
                        height="150"
                        width="150"
                        class="img"
                        :src="
                          serviceFile.url
                            ? serviceFile.url
                            : '/image-placeholder.png'
                        "
                      />
                      <v-icon x-large class="img-icon white--text"
                        >mdi-camera</v-icon
                      >
                      <div class="img-loading" v-if="profileLoading">
                        <v-progress-circular
                          indeterminate
                          color="primary"
                          :size="40"
                        ></v-progress-circular>
                        <h6 class="mt-4 grey--text text--darken-2">
                          در حال بارگذاری...
                        </h6>
                      </div>
                    </div>
                    <v-btn
                      class="purple-btn mb-4 ms-4 pa-5"
                      @click="$refs.profileImage.$refs.input.click()"
                    >
                      <v-icon class="img-icon me-2">mdi-camera-outline</v-icon
                      >بارگذاری تصویر</v-btn
                    >
                    <v-file-input
                      v-show="false"
                      @change="uploadProfile(imageFile)"
                      v-model="imageFile"
                      ref="profileImage"
                    ></v-file-input>
                  </div>
                </v-col>
              </v-row>
              <v-btn
                class="submit-btn primary-btn mt-4"
                v-if="type == 'clinic'"
                @click="newService()"
                :disabled="
                  !(
                    service.name &&
                    service.type &&
                    service.docPercent >= 0 &&
                    service.docPercent <= 100
                  )
                "
                >ثبت</v-btn
              >
              <v-btn
                class="submit-btn primary-btn mt-4"
                v-else
                @click="newService()"
                :disabled="!service.name"
                >ثبت</v-btn
              >
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: ["type", "label", "back"],
  data() {
    return {
      service: {},
      choose: true,
      serviceTypes: [
        { value: "ویزیت", text: "ویزیت" },
        { value: "ویزیت تخصصی", text: "ویزیت تخصصی" },
        { value: "خدمات تخصصی", text: "خدمات تخصصی" },
        { value: "خدمات پرستاری", text: "خدمات پرستاری" },
        { value: "خدمات پزشک", text: "خدمات پزشک" },
        { value: "اقلام مصرفی", text: "اقلام مصرفی" },
      ],
      categories: [
        { value: "clinic", text: "سرویس‌های در درمانگاه" },
        { value: "outClinic", text: "سرویس‌های در منزل" },
        { value: "dental", text: "سرویس‌های دندانپزشکی" },
      ],
      profileLoading: false,
      imageFile: [],
      myImageFile: new Object({
        uploadPercentage: 0,
        image: "",
      }),
      serviceFile: {},
    };
  },

  methods: {
    newService() {
      this.vLoading = true;
      //NOTE defining endpoint from chosen serviceType
      let endpoint;
      if (this.type == "clinic") {
        endpoint = "/clinic/services/newService";
      } else if (this.type == "outClinic") {
        endpoint = "/clinic/services/newOutService";
      } else if (this.type == "dental") {
        endpoint = "/clinic/dentist/newService";
      }
      //NOTE sending new service data to server
      this.$http
        .post(
          this.baseUrl + endpoint,
          {
            name: this.service.name,
            cost: this.service.cost ? this.service.cost : "0",
            docPercent: this.service.docPercent ? this.service.docPercent : "0",
            expense: this.service.expense ? this.service.expense : "0",
            type: this.type == "dental" ? "دندانپزشکی" : this.service.type,
            protectiveExpense: this.service.protectiveExpense
              ? this.service.protectiveExpense
              : "0",
            isSelectableByPatient: this.service.isSelectableByPatient,
            fileId:
              this.type != "outClinic"
                ? this.serviceFile
                  ? this.serviceFile.id
                  : undefined
                : undefined,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.vLoading = false;
          if (res.status == 201) {
            this.toast(res.data, "success");
            if (this.choose) {
              this.service = {};
            } else {
              this.$router.push(this.back);
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
        });
    },
    percent(value) {
      return value
        ? value < 0 || value > 100
          ? "مقدار سهم باید بین صفر تا صد باشد!"
          : true
        : true;
    },
    emptyData() {
      this.service = {};
      this.imageFile = [];
      this.myImageFile = new Object({
        uploadPercentage: 0,
        image: "",
      });
      this.serviceFile = {};
    },
    uploadProfile(file) {
      if (!file) {
        return;
      }
      let sizeMB = file.size / 1024 / 1024;

      if (sizeMB > 5) {
        this.toast(
          "حجم فایل " + file.name + " نباید از 5 مگابایت بیشتر باشد",
          "error"
        );
        return;
      } else if (file.type != "image/jpeg" && file.type != "image/png") {
        this.toast("فرمت فایل مورد نظر مجاز نمی‌باشد.", "error");
        return;
      } else {
        // NOTE (m-myUpload) to upload files
        this.myImageFile.showProgress = true;
        this.myImageFile.variant = "info";
        this.myImageFile.uploadPercentage = 0;
        this.myImageFile.isUploaded = false;
        this.myImageFile.uploadButDisable = true;
        var formData = new FormData();
        formData.append(file.name, file);
        this.profileLoading = true;
        this.$http
          .post(this.baseUrl + "/clinic/services/upload", formData, {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
            onUploadProgress: ((progressEvent) => {
              this.myImageFile.uploadPercentage =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) -
                1;
            }).bind(this),
          })
          .then((d) => {
            if (d.status == 200) {
              this.myImageFile.isUploaded = true;
              this.myImageFile.uploadPercentage = 100;
              this.myImageFile.striped = false;
              this.myImageFile.variant = "success";
              this.myImageFile.uploadButDisable = false;
              this.serviceFile = d.data;
              this.profileLoading = false;
            }
          })
          .catch((err) => {
            console.log(err);
            this.toast(err.response.data, "error");
            this.profileLoading = false;
          });
      }
    },
  },

  mounted() {
    if (this.type) {
      this.choose = false;
    } else {
      this.choose = true;
    }
  },
};
</script>
